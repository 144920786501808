import axios from 'axios'
import env from "./env";
import { Message } from "element-ui";
import router from "../router/index.js"

export function request(config) {
    //  1.创建axios的实例
  const instance = axios.create({
    baseURL:env.baseUrl,
    timeout: 5000
  })
  // 2. axios的拦截器
  // 请求拦截
  // 在 request 拦截器中，展示进度条 NProgress.start()
  instance.interceptors.request.use(config => {
      //  console.log(config)
      //  1.比如config中的某些信息不符合要求
      //  2.比如每次发送网络请求时，都希望在界面中显示一个请求的图标
      //  3.某些网络请求比如登录，必须携带一些特殊的信息token
			// if(config.url === 'datum/register') {console.log(1)}
			if (sessionStorage.getItem("token")) {
			  config.headers.Authorization =
			    "Bearer " + sessionStorage.getItem("token");
			}
      // config.headers.Authorization = window.sessionStorage.getItem('token')
       return config
  }, err => {
       return err
  });
  // 响应拦截
  // 在 response 拦截器中，隐藏进度条 NProgress.done()
  instance.interceptors.response.use(res => {
   if(res.data.code == 1 ) {
		 // Message.success({
		 // 	message:res.data.msg,
		 // 	duration:1000,
		 // });
	 }else if(res.data.code == 0) {
		 Message.error({
		 	message:res.data.msg,
		 	duration:5000,
		 });
	 }else if(res.data.code == 'ParamaterInvalid'){
		 Message.error({
		 	message:'无效参数',
		 	duration:5000,
		 });
	 }else if(res.data.code == 'AccountInvalid'){
		 Message.error({
		 	message:'无效账号或密码错误',
		 	duration:5000,
		 });
	 }else if(res.data.code == 'AccountLocked'){
		 Message.error({
		 	message:'账号已锁定',
		 	duration:5000,
		 });
	 }else if(res.data.code == 'AccountOffline'){
		 Message.error({
		 	message:'账号已离线',
		 	duration:5000,
		 });
	 }else if(res.data.code == 'AccessKeyInvalid'){
		 Message.error({
		 	message:'密钥错误或超时',
		 	duration:5000,
		 });
	 }else if(res.data.code == 'Unauthorized'){
		 Message.error({
		 	message:'未授权对该资源的操作',
		 	duration:5000,
		 });
	 }else if(res.data.code == 'Forbidden'){
		 Message.error({
		 	message:'数据保护不允许该操作',
		 	duration:5000,
		 });
	 }else if(res.data.code == 'NotFound'){
		 Message.error({
		 	message:'资料不存在',
		 	duration:5000,
		 });
	 }else if(res.data.code == 'SourceConflict'){
		 Message.error({
		 	message:'发生冲突，该资料已创建',
		 	duration:5000,
		 });
	 }else if(res.data.code == 'ServerError'){
		 Message.error({
		 	message:'服务器发生异常',
		 	duration:5000,
		 });
	 }else if(res.data.code == 'DataException'){
		 Message.error({
		 	message:'无法解析服务器返回的数据',
		 	duration:5000,
		 });
	 }else if(res.data.code == 'NetworkException'){
		 Message.error({
		 	message:'网络发生异常，无法连接服务器',
		 	duration:5000,
		 });
	 }else if(res.data.code == 'Console_Connect_TokenInvalid'){
		 Message.error({
		 	message:'无效Token',
		 	duration:5000,
		 });
		 router.replace("/login");
	 }else if(res.data.code == 'Console_Login_NeedLogin'){
		 Message.error({
		 	message:'需要登录',
		 	duration:5000,
		 });
		 router.replace("/login");
	 }
		
    return res.data
  },err => {
    // console.log(err)
    return err
  });
  // 3. 发送真正的网络请求
   return instance(config)
  
}
export function homeRequest(config) {
  //  1.创建axios的实例
const instance = axios.create({
  baseURL:'http://127.0.0.1:8888/api/private/v1/',
  timeout: 5000
})
// 2. axios的拦截器
// 请求拦截
instance.interceptors.request.use(config => {
     return config
}, err => {
     return err
});
// 响应拦截
instance.interceptors.response.use(res => {
  // console.log(res);
  return res.data
},err => {
  // console.log(err)
  return err
});
// 3. 发送真正的网络请求
 return instance(config)

}