let protocol = window.location.protocol; //协议
let host = window.location.host; //主机
let reg = /^localhost+/;
var baseUrl = ''
console.log(protocol, host)
if (reg.test(host) || host.indexOf('192.168.0') != -1) {
	//若本地项目调试使用
	// baseUrl = 'http://192.168.0.110:9501/v1/api/'
	baseUrl = 'http://www.safety365.vip/v1/api/'
} else {
	//动态请求地址             协议               主机
	// baseUrl = 'http://safety365.shenganyun.cn/v1/api/'
	baseUrl = protocol + "//" + host + '/v1/api/';
}
const env = {
	// 后端服务器
	// base: "http://safety365.shenganyun.cn/v1/api/",
	base: protocol + "//" + host + '/v1/api/',
	baseUrl: baseUrl
};
export default env;
